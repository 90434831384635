/* website: 2137-mercedes-benz-laval
 * created at 2021-10-28 7:30 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/mercedes-benz.scss";
@import "../utils/icons.scss";

.widget-sr{
  &.dealer__mercedes-benz-laval{
    .listing-tile-all-price{
      .listing-tile-all-price-retails{
        &.rebated{
         display: none;
        }
      }
    }
  }
}